define('application/main',[
  "application/options",
  //"i18n!nls/text",
  "jquery",
  "application/moveToFragment",
  "application/carousel",
  "datepicker"
], function (
  appOptions,
  //text,
  $,
  appMoveToFragment,
  appCarousel
) {
  var $window = $(window);
  var $document = $(document);
  var $html = $("html");
  var $body = $("body");

  var locale = $html.attr("lang");

  appMoveToFragment($document);
  appCarousel($document);

  $document.on("app.domset", function (event, data) {
    var $menu = data.dom.find(".js-menu-shadow");
    var $logoMenu = data.dom.find(".js-logo-menu");
    var $header = data.dom.find(".js-header");

    $menu.each(function () {
      $window.on("scroll", function () {
        if ($window.scrollTop() > 0) {//} && false === $menu.hasClass("shadow")) {
          $menu.addClass("shadow");
          $logoMenu.addClass("visible");
          $header.removeClass("visible")
        } else {
          $menu.removeClass("shadow");
          $logoMenu.removeClass("visible");
          $header.addClass("visible");
        }
      });
    })
  });

  $document.on("app.domset", function (event, data) {
    var $animateOnScroll = data.dom.find(".js-animate-on-scroll");
    var windowHeight = 0;
    var recalculatePositions = function ($elements) {
      $elements.each(function () {
        var $this = $(this);

        $this.data("animate-on-scroll-position", $this.offset().top);
        $this.data("animate-on-scroll-height", $this.height());
      });

      windowHeight = $window.height();
    };

    recalculatePositions($animateOnScroll);
    $window.on("resize", function () { recalculatePositions($animateOnScroll); });

    $window.on("scroll", function () {
      $animateOnScroll.each(function () {
        var $this = $(this);

        var xPosition = $this.data("animate-on-scroll-position");
        //var xHeight = $this.data("animate-on-scroll-height");
        var yPosition = $window.scrollTop();
        var c = yPosition > (xPosition - windowHeight) + (windowHeight / 8);

        /*console.log({
          'xPosition': xPosition,
          //'xHeight': xHeight,
          'yPosition': yPosition,
          'windowHeight': windowHeight,
          'c': c
        });*/

        if (c) {
          $this.addClass("visible");
        } else {
          $this.removeClass("visible");
        }
      });
    });
  });

  $document.on("app.domset", function (event, data) {
    var $datepicker = data.dom.find(".js-datepicker");

    $datepicker.each(function () {
      $(this).datepicker({
        format: "yyyy-mm-dd",
      });
    });
  });

  $document.on("app.domset", function (event, data) {
    var $close = data.dom.find(".js-close");

    $close.each(function () {
      var $this = $(this);

      $this.on("click", function (event) {
        event.stopImmediatePropagation();

        $($this.data("close").target).fadeOut();
      });
    });
  });

  $document.on("app.domset", function (event, data) {
    var $trigger = data.dom.find(".js-player-trigger");

    $trigger.each(function () {
      var $this = $(this);

      $this.on("click", function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        $(".js-player").fadeIn();
        $(".js-player iframe").attr("src", $this.data("player").player_url);
      });
    });
  });

  $document.on("app.domset", function (event, data) {
    var $trigger = data.dom.find(".js-slide-trigger");
    var $next = data.dom.find(".js-slide-next");
    var $previous = data.dom.find(".js-slide-previous");

    $trigger.each(function () {
      var $this = $(this);

      $this.on("click", function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        var $slide = $($this.data("slide").target);

        $slide.data("index", $this.data("slide").index);
        $slide.data("length", $this.data("slide").length);

        $slide.fadeIn();
        $slide.find("img").attr("src", $this.data("slide").slide_url);
      });
    });

    $next.on("click", function (event) {
      event.stopImmediatePropagation();

      var $slide = $($(this).data("slide").target);
      var $image = $slide.find("img");

      $image.fadeOut(function () {
        var next = $slide.data("index") === ($slide.data("length") - 1) ? 0 : $slide.data("index") + 1;

        $trigger.eq(next).trigger("click");
        $image.fadeIn();
      })
    });

    $previous.on("click", function (event) {
      event.stopImmediatePropagation();

      var $slide = $($(this).data("slide").target);
      var $image = $slide.find("img");

      $image.fadeOut(function () {
        var previous = $slide.data("index") === 0 ? $slide.data("length") - 1 : $slide.data("index") - 1;

        $trigger.eq(previous).trigger("click");
        $image.fadeIn();
      })
    });
  });

  $document.trigger("app.domset", [{dom: $body}]);

  // run it when the page is already scrolled on refresh so the events based on scrolling can fire at load
  $window.trigger("scroll");

  // run it when the page is loaded so the events based on resizing can fire at load
  $window.trigger("resize");
});
