require.config({
  baseUrl: "/main/js",
  paths: {
    //"propertyParser": "../../bower_components/requirejs-plugins/src/propertyParser",
    //"async": "../../bower_components/requirejs-plugins/src/async",
    //"goog": "../../bower_components/requirejs-plugins/src/goog",
    "jquery": [
      "//cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min",
      "vendor/jquery/3.4.1/jquery.min"
    ],
    /*"wow": [
      "//cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min",
      "vendor/wow/1.1.2/wow.min"
    ],*/
    "owl-carousel": [
      "//cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min",
      "vendor/owl-carousel/2.3.4/owl.carousel.min"
    ],
    "datepicker": "vendor/datepicker/1.0.9/datepicker.min"
  },
  shim: {
    "jquery": {
      exports: "jQuery"
    },
    "owl-carousel": ["jquery"],
    "datepicker": ["jquery"]
  }
});

require(["application/main"]);
define("main", function(){});

