define('application/carousel',["application/options", "owl-carousel"], function (appOptions) {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-owl-carousel").each(function () {
        var $this = $(this);

        $this.owlCarousel($.extend({}, appOptions.owlCarousel, $this.data("owl-carousel")));
      });
    });
  }
});
