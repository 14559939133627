define('application/options',[],function () {
  return {
    moveToFragment: {
      staticHeaderSelector: ".js-move-to-fragment.js-menu"
    },
    owlCarousel: {
      autoplay: true,
      loop: true,
      items: 1,
      //animateOut: "fadeOut",
      responsive: {
        0: {
          stagePadding: 30,
        },
        992: {
          stagePadding: 200,
        }
      }
    },
  }
});
